<template>
  <div>
    <div class="tw-border-b tw-border-gray-300">
      <div class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5">
        <v-layout row wrap>
          <v-flex xs6>
            <h3 class="tw-text-2xl tw-capitalize tw-font-medium tw-inline-flex tw-items-center">
              {{ $t('contacts') }}
              <tooltip-select tip="_contacts" class="tw-ml-2"></tooltip-select>
            </h3>
          </v-flex>
        </v-layout>
        <div class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5">
          <create-contact :refresh="refresh" v-if="checkScope(scopeLiterals.ContactsCreate)" />
          <v-menu offset-y>
            <v-btn slot="activator" color="secondary" dark :small="true" colo class="secondary">
              {{ $tc('_action', 2) }}
              <i class="material-icons"> arrow_drop_down </i>
            </v-btn>
            <v-list>
              <v-list-tile v-if="checkScope(scopeLiterals.ContactsUpdate)">
                <v-list-tile @click="disableAll()">
                  <v-list-tile-title>Disable All</v-list-tile-title>
                </v-list-tile>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <div class="tw-py-4 tw-px-3">
      <contacts ref="contactsRef" />
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';

import Contacts from '../../components/company/Contacts.vue';
import CreateContact from '../../components/CreateContact.vue';

export default {
  props: [],
  components: {
    Contacts: Contacts,
    CreateContact,
  },
  methods: {
    refresh() {
      this.$refs.contactsRef.refresh();
    },
    async disableAll() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
      };

      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!) {
              disableContacts(orgId: $orgId) {
                success
              }
            }
          `,
          // Parameters
          variables: vars,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
